import * as Yup from "yup";

const useSizeConfig = () => {
  const validationSchema = Yup.object({
    sizename: Yup.string().required("Size name is required"),    
  });

  const initialValues = {
    id:0,
    sizename: "",
    size_status: 0,    
    loginid:0,
  };

  return { validationSchema, initialValues };
};

export default useSizeConfig;
