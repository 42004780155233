import React, { useEffect, useState } from "react";
import { Container, Spinner, Badge, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setSizeSelectedList } from "../../../API/Reducer/fabricReducer";
import { useMutation } from "@tanstack/react-query";
import { productSize_addItem, productSize_deleteItem } from "../../../API/Service/productSizeService";
import SizeListCheckbox from "./SizeListCheckbox";

const SizeList = ({ data }) => {
  const dispatch = useDispatch();
  const { sizeSelectedList } = useSelector((state) => state.fabric);
  const { subProductData } = useSelector((state) => state.mainproduct);
  const [checkBoxLoading, setCheckBoxLoading] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [showModalSize, setShowModalSize] = useState(false);
  const [alertValue, setAlertValue] = useState({ field: "", value: null });

  // Handle checkbox toggle
  const handleCheckboxChange = (id) => {  
    if(Object.keys(subProductData).length > 0){return;}
    const updatedItems = sizeSelectedList.map((item) =>
      item.id === id ? { ...item, is_check: !item.is_check } : item
    );
    dispatch(setSizeSelectedList(updatedItems));
  };

  // Handle input value changes
  const handleChange = (id, field, newValue) => {
    const updatedItems = sizeSelectedList.map((item) =>
      item.id === id ? { ...item, [field]: newValue } : item
    );
    dispatch(setSizeSelectedList(updatedItems));    
  };

  // Handle modal logic for applying to all sizes
  const handleBlur = (id, field, newValue) => {
    if (id === 0 && newValue > 0) {
      setAlertValue({ field, value: newValue });
      setShowModal(true);
    }
  };

  const confirmUpdateForAllSizes = () => {
    const updatedItems = sizeSelectedList.map((item) => ({
      ...item,
      [alertValue.field]: alertValue.value
    }));
    dispatch(setSizeSelectedList(updatedItems));
    setShowModal(false);
  };

  const cancelUpdateForAllSizes = () => {
    setShowModal(false);
  };  

  const deleteMutation = useMutation({
    mutationFn: productSize_deleteItem,    
    onSuccess: () => {           
    },
    onError: (error) => {            
    },    
  });

  const saveMutation = useMutation({
    mutationFn: productSize_addItem,   
    onSuccess: () => {     
     },
    onError: (error) => {      
     },    
  });

  const handleCheckboxValue = (element,sid,value) => {     
    const updatedItems = sizeSelectedList.map((item) =>
       item.id === element?.id ? 
       { ...item, is_check:value,sid:sid,
        ps_mrp:element?.ps_mrp,
        ps_price:element?.ps_price,
        ps_whlprice:element?.ps_whlprice,
       } : item
     );    
     dispatch(setSizeSelectedList(updatedItems));
   };

  const handleSubmit = (element,is_check) => {
    if(Object.keys(subProductData).length > 0 && (element?.sid > 0 || element?.id > 0)){                  
      setCheckBoxLoading((prev) => ({ ...prev, [element?.id]: true })); 
      if(is_check){
          const formData = new FormData();
          formData.append("subid",subProductData?.id);
          formData.append("sid",element?.id);
          formData.append("loginid",0);
          formData.append("ps_status",0);
          formData.append("ps_mrp",element?.ps_mrp);
          formData.append("ps_price",element?.ps_price);
          formData.append("ps_whlprice",element?.ps_whlprice);
                    
          saveMutation.mutate(formData, {
            onSuccess: (data) => {                
              let sid=data?.data?.id;          
              handleCheckboxValue(element,sid,true)
              setCheckBoxLoading((prev) => ({ ...prev, [element?.id]: false }));
            },
            onError: (error) => {
              setCheckBoxLoading((prev) => ({ ...prev, [element?.id]: false }));
            }
          });
          
        }else{          
          deleteMutation.mutate({id:element?.sid}, {
            onSuccess: () => {              
              handleCheckboxValue(element,0,false)
              setCheckBoxLoading((prev) => ({ ...prev, [element?.id]: false }));
            },
            onError: (error) => {
              setCheckBoxLoading((prev) => ({ ...prev, [element?.id]: false }));
            }
          });
             
        }       
      }
  };

  const handleUpdatePrice = (id, field, newValue) => {
    if(Object.keys(subProductData).length > 0 && id > 0){                  
      const formData = new FormData();
          formData.append("id",id);                    
          formData.append(field,newValue);                              
          saveMutation.mutate(formData, {
            onSuccess: (data) => {                                            
            },
            onError: (error) => {              
            }
          });      
    }
  };

  const closeModalSizes = () => {
    setShowModalSize(false);
  };
  const openModalSizes = () => {
    setShowModalSize(true);
  };
  const handleKeyPress = (event) => {        
    if (event.key === 'Enter') {
      closeModalSizes();
    }
  };
  const handleKeyPressPrice = (event) => {
    if (event.key === 'Enter') {
      confirmUpdateForAllSizes();
    }
  };
  useEffect(() => {
    if (showModal) {
      window.addEventListener("keydown", handleKeyPressPrice);
    } else {
      window.removeEventListener("keydown", handleKeyPressPrice);
    }

    if (showModalSize) {
      window.addEventListener("keydown", handleKeyPress);
    } else {
      window.removeEventListener("keydown", handleKeyPress);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyPressPrice);
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [showModal, showModalSize]);
  return (
    <>
      <Container>
        <div className="scrollable-table" style={{ maxHeight: "300px", fontSize: "0.85rem" }}>
          {data?.isSizeLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "250px" }}>
              <Spinner animation="border" />
            </div>
          ) : data?.isSizeError ? (
            <div className="text-danger text-center">
              <i className="bi bi-exclamation-triangle-fill"></i> Failed to load data.
            </div>
          ) : data?.sizes?.length > 0 ? (
            <>             
              <div className="p-1 d-flex justify-content-between align-items-center bg-light rounded">
                <Badge
                  bg="primary"
                  className="px-2 py-1 text-white"
                  style={{ fontSize: "0.75rem", borderRadius: "6px" }}
                >
                  Selected Fabrics:{" "}
                  {sizeSelectedList.filter((item) => item.is_check).length}
                </Badge>
                <button
                  onClick={openModalSizes}
                  type="button"
                  className="btn btn-primary btn-sm"
                  style={{
                    fontSize: "0.75rem",
                    padding: "4px 10px",
                    borderRadius: "6px",
                  }}
                >
                  + Add
                </button>
              </div>
              <table className="table table-hover w-100">
                <thead>
                  <tr>
                    <th style={{ width: "50px" }}></th>
                    <th>Size</th>
                    <th>MRP (₹)</th>
                    <th>Teelelo Price (₹)</th>
                    <th>TBKA Price (₹)</th>
                  </tr>
                </thead>
                <tbody className="list">
                  {sizeSelectedList?.filter((element) => element?.is_check)?.map((element,index) => {                  
                    return (
                      <tr className={`p-2`} key={element.id}>
                        <td>
                        {
                        checkBoxLoading[element.id] ?
                        (<Spinner animation="border" size="sm" />)
                        :(
                          <input
                            type="checkbox"
                            className="form-check-input"
                            style={{
                              width: "20px",
                              height: "20px",
                              border: "1px solid #ddd",
                              borderRadius: "2px",
                            }}
                            checked={element?.is_check}
                            onChange={(e) => {
                              handleSubmit(element,e.target.checked)
                              handleCheckboxChange(element?.id)}}
                          />
                          )}
                        </td>
                        <td>{element?.sizename}</td>
                        <td>
                          <input
                            type="number"
                            value={element?.ps_price || ""}
                            onChange={(e) =>
                              handleChange(element?.id, "ps_price", e.target.value)
                            }
                            onBlur={(e) =>{
                              handleBlur(index, "ps_price", e.target.value)
                              handleUpdatePrice(element?.sid, "ps_price", e.target.value)
                            }}
                            style={{
                              width: "80px",
                              fontSize: "0.85rem",
                              border: "1px solid #ddd",
                              borderRadius: "3px",
                              padding: "2px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={element?.ps_mrp ||""}
                            onChange={(e) =>
                              handleChange(element?.id, "ps_mrp", e.target.value)
                            }
                            onBlur={(e) =>
                              {handleBlur(index, "ps_mrp", e.target.value)
                              handleUpdatePrice(element?.sid, "ps_mrp", e.target.value)
                              }
                            }
                            style={{
                              width: "80px",
                              fontSize: "0.85rem",
                              border: "1px solid #ddd",
                              borderRadius: "3px",
                              padding: "2px",
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={
                              element?.ps_whlprice ||""
                            }
                            onChange={(e) =>
                              handleChange(element?.id, "ps_whlprice", e.target.value)
                            }
                            onBlur={(e) =>
                            {
                              handleBlur(index, "ps_whlprice", e.target.value)
                              handleUpdatePrice(element?.sid, "ps_whlprice", e.target.value)
                              }
                            }
                            style={{
                              width: "80px",
                              fontSize: "0.85rem",
                              border: "1px solid #ddd",
                              borderRadius: "3px",
                              padding: "2px",
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <div className="text-center text-muted">No sizes available.</div>
          )}
        </div>
      </Container>

      {/* Modal for updating all sizes */}
      <Modal show={showModal} onHide={cancelUpdateForAllSizes} centered    
      >
        <Modal.Header closeButton>
          <Modal.Title>Update All Sizes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to update the {alertValue.field.toUpperCase()} for all sizes to ₹
            {alertValue.value}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={confirmUpdateForAllSizes}>
            Yes
          </Button>
          <Button variant="secondary" onClick={cancelUpdateForAllSizes}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalSize} onHide={closeModalSizes} centered size="lg"      
      >
        <Modal.Header closeButton>
          <Modal.Title>Sizes</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          maxHeight: '400px',
          overflowY: 'auto',
        }}>
          <SizeListCheckbox/>
        </Modal.Body> 
        <Modal.Footer>
        <Button variant="primary" onClick={closeModalSizes}>
          OK
        </Button>
      </Modal.Footer>
      </Modal>

    </>
  );
};

export default SizeList;
