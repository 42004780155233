import React, { createContext, useContext, useState } from 'react';
import ConfirmationModal from './ConfirmationModal';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modalConfig, setModalConfig] = useState({
    show: false,
    title: "",
    message: "",
    onConfirm: null,
    confirmText: "Yes",
    cancelText: "No",
    size:"sm"
  });

  const showModal = (config) => {
    setModalConfig({ ...modalConfig, ...config, show: true });
  };

  const hideModal = () => {
    setModalConfig((prev) => ({ ...prev, show: false }));
  };

  const handleConfirm = () => {
    if (modalConfig.onConfirm) modalConfig.onConfirm();
    hideModal();
  };

  return (
    <ModalContext.Provider value={{ showModal }}>
      {children}
      <ConfirmationModal
        show={modalConfig.show}
        onHide={hideModal}
        title={modalConfig.title}
        message={modalConfig.message}
        onConfirm={handleConfirm}
        confirmText={modalConfig.confirmText}
        cancelText={modalConfig.cancelText}
        size={modalConfig.size}
      />
    </ModalContext.Provider>
  );
};
