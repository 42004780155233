import { createSlice } from "@reduxjs/toolkit";
 
 
const productReducer = createSlice({
  name: "product",
  initialState: {     
    tbkProductLoading:false,
    telProductLoading:false,    
    tbkProductData:{},
    telProductData:{},    
  },

  reducers: {          
    setTbkProductLoading(state, action) {      
      state.tbkProductLoading = !state.tbkProductLoading
    },
    setTelProductLoading(state, action) {      
        state.telProductLoading = !state.telProductLoading
      },    
    setTbkProductData(state,action){
      state.tbkProductData=action.payload
    },
    setTelProductData(state,action){
        state.telProductData=action.payload
    },     
  },
});

export const {
    setTbkProductLoading,setTelProductLoading,
    setTbkProductData,setTelProductData
} = productReducer.actions;
export default productReducer.reducer;
