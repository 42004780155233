import React from "react";
import MainTabs from "./MainTabs";
function AdminContent() {
  return (
    <>
      <MainTabs />
    </>
  );
}

export default AdminContent;
