import React, { useState } from "react";
import { Container, Spinner, Badge, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setFabricSelectedList } from "../../../API/Reducer/fabricReducer";
import { useMutation } from "@tanstack/react-query";
import {
  productFabric_addItem,
  productFabric_deleteItem,
} from "../../../API/Service/productFabricService";
import FabricListCheckbox from "./FabricListCheckbox";

const FabricList = ({ fabrics }) => {
  const dispatch = useDispatch();
  const { fabricSelectedList } = useSelector((state) => state.fabric);
  const { subProductData } = useSelector((state) => state.mainproduct);
  const [checkBoxLoading, setCheckBoxLoading] = useState(false);
  const [fabricModalSize, setFabricModalSize] = useState(false);

  // Handle checkbox toggle
  const handleCheckboxChange = (id) => {
    if (Object.keys(subProductData).length > 0) {
      return;
    }
    const updatedItems = fabricSelectedList.map((item) =>
      item.id === id ? { ...item, is_check: !item.is_check } : item
    );
    dispatch(setFabricSelectedList(updatedItems));
  };

  const handleCheckboxValue = (id, fbId, value) => {
    const updatedItems = fabricSelectedList.map((item) =>
      item.id === id ? { ...item, is_check: value, fbid: fbId } : item
    );
    dispatch(setFabricSelectedList(updatedItems));
  };

  const deleteMutation = useMutation({
    mutationFn: productFabric_deleteItem,
    onSuccess: () => {},
    onError: (error) => {},
  });

  const saveMutation = useMutation({
    mutationFn: productFabric_addItem,
    onSuccess: () => {},
    onError: (error) => {},
  });

  const handleSubmit = (delId, newId, is_check) => {
    if (Object.keys(subProductData).length > 0 && (delId > 0 || newId > 0)) {
      setCheckBoxLoading((prev) => ({ ...prev, [newId]: true }));
      if (is_check) {
        const formData = new FormData();
        formData.append("subid", subProductData?.id);
        formData.append("fbid", newId);
        formData.append("loginid", 0);
        formData.append("pfb_status", 0);

        saveMutation.mutate(formData, {
          onSuccess: (data) => {
            let fbId = data?.data?.id;
            handleCheckboxValue(newId, fbId, true);
            setCheckBoxLoading((prev) => ({ ...prev, [newId]: false }));
          },
          onError: (error) => {
            setCheckBoxLoading((prev) => ({ ...prev, [newId]: false }));
          },
        });
      } else {
        deleteMutation.mutate(
          { id: delId },
          {
            onSuccess: () => {
              handleCheckboxValue(newId, 0, false);
              setCheckBoxLoading((prev) => ({ ...prev, [newId]: false }));
            },
            onError: (error) => {
              setCheckBoxLoading((prev) => ({ ...prev, [newId]: false }));
            },
          }
        );
      }
    }
  };

  const closeModalFabric = () => {
    setFabricModalSize(false);
  };
  const openModalFabric = () => {
    setFabricModalSize(true);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      closeModalFabric();
    }
  };
  return (
    <>
      <Container>
        <div
          className="scrollable-table"
          style={{ maxHeight: "250px", fontSize: "0.8rem" }} // Smaller font size
        >
          {fabrics?.isLoadingFabric ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              <Spinner animation="border" size="sm" /> {/* Smaller spinner */}
            </div>
          ) : fabrics?.isErrorFabric ? (
            <div
              className="text-danger text-center"
              style={{ fontSize: "0.9rem" }}
            >
              <i className="bi bi-exclamation-triangle-fill"></i> Failed to load
              data.
            </div>
          ) : fabrics?.fabrics?.length > 0 ? (
            <>
              <div className="p-1 d-flex justify-content-between align-items-center bg-light rounded">
                <Badge
                  bg="primary"
                  className="px-2 py-1 text-white"
                  style={{ fontSize: "0.75rem", borderRadius: "6px" }}
                >
                  Selected Fabrics:{" "}
                  {fabricSelectedList.filter((item) => item.is_check).length}
                </Badge>
                <button
                  onClick={openModalFabric}
                  type="button"
                  className="btn btn-primary btn-sm"
                  style={{
                    fontSize: "0.75rem",
                    padding: "4px 10px",
                    borderRadius: "6px",
                  }}
                >
                  + Add
                </button>
              </div>

              <table
                className="table table-hover w-100"
                style={{ fontSize: "0.8rem" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "40px" }}></th>{" "}
                    {/* Narrower checkbox column */}
                    <th>Fabric</th>
                  </tr>
                </thead>
                <tbody className="list">
                  {fabricSelectedList
                    ?.filter((element) => element.is_check)
                    ?.map((element, index) => {
                      return (
                        <tr
                          className={`p-2`} // Smaller padding
                          key={index}
                        >
                          {/* Checkbox */}
                          <td>
                            {checkBoxLoading[element.id] ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              <input
                                type="checkbox"
                                className="form-check-input"
                                style={{
                                  width: "16px", // Smaller checkbox size
                                  height: "16px",
                                  border: "1px solid #ddd",
                                  borderRadius: "2px",
                                }}
                                checked={element?.is_check}
                                onChange={(e) => {
                                  handleSubmit(
                                    element?.fbid,
                                    element?.id,
                                    e.target.checked
                                  );
                                  handleCheckboxChange(element?.id);
                                }}
                              />
                            )}
                          </td>

                          {/* Fabric Name */}
                          <td style={{ padding: "4px" }}>
                            {element?.fabricname}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          ) : (
            <div
              className="text-center text-muted"
              style={{ fontSize: "0.75rem" }}
            >
              No fabrics available.
            </div>
          )}
        </div>
      </Container>

      <Modal
        show={fabricModalSize}
        onHide={closeModalFabric}
        centered
        size="lg"        
        onKeyDown={(e)=>{handleKeyPress(e)}}
      >
        <Modal.Header closeButton>
          <Modal.Title>Fabric</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          maxHeight: '400px',
          overflowY: 'auto',
        }}>
          <FabricListCheckbox />
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={closeModalFabric}>
          OK
        </Button>
      </Modal.Footer>
      </Modal>
    </>
  );
};

export default FabricList;
