import { createSlice } from "@reduxjs/toolkit";
 
 
const productCategoryReducer = createSlice({
  name: "product_category",
  initialState: { 
    productCatgModal: false,   
    productCatgLoading:false,
    productCatgData:{},
  },

  reducers: {       
    setProductCatgModal(state, action) {      
      state.productCatgModal = !state.productCatgModal
    },
    setProductCatgLoading(state, action) {      
      state.productCatgLoading = !state.productCatgLoading
    },
    setProductCatgData(state, action) {      
        state.productCatgData = action.payload
    },
     
  
  },
});

export const {
    setProductCatgModal,setProductCatgLoading,setProductCatgData
} = productCategoryReducer.actions;
export default productCategoryReducer.reducer;

 


