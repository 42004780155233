import React, { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FloatingLabelInput from "../GlobalComponent/FloatingLabelInput";
import { Formik, Form } from "formik";
import useFabricConfig from "../../API/Hooks/useFabricConfig";
import { useDispatch, useSelector } from "react-redux";
import { setFabricData, setFabricLoading, setFabricModal } from "../../API/Reducer/fabricReducer";
import { FaRegEdit, FaSave, FaTrash } from "react-icons/fa";
import SearchField from "../GlobalComponent/SearchField ";
import { fabric_addItem, fabric_deleteItem, fabric_fetchItems } from "../../API/Service/fabricService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { showToast } from "../../API/Reducer/toastSlice";
import ButtonEL from "../GlobalComponent/Elements/ButtonEL";
import { useModal } from "../ConfirmationModal/ModalContext";

const FabricMaster = () => {
  const queryClient = useQueryClient();
  const { validationSchema, initialValues } = useFabricConfig();
  const dispatch = useDispatch();
  const { fabricModal,fabricLoading,fabricData } = useSelector((state) => state.fabric);
  const [searchTerm, setSearchTerm] = useState("");
  const { showModal } = useModal();

  const { data: fabrics=[], isLoading, isError } = useQuery({
    queryKey: ["fabrics"],
    queryFn: fabric_fetchItems,
    onSuccess: (data) => {            
      // console.log("Fetched sizes data:", data);
    },
  });

  const mutation = useMutation({
    mutationFn: fabric_addItem,
    onMutate: () => {      
      dispatch(setFabricLoading())
    },
    onSuccess: (resData) => {      
      queryClient.invalidateQueries(["fabrics"]);
      if(resData?.code==200 && Object.keys(resData?.data).length>0){
        dispatch(
            showToast({
              message: "Record saved successfully",
              variant: "success",
            })
          );          
      }else if(resData?.code==200 && resData?.data.length<=0){
        dispatch(
            showToast({
              message: `This Fabric Already Exist`,
              variant: "danger",
            })
          );
      }else{
        dispatch(
            showToast({
              message: "something went wrong; Please try again!",
              variant: "danger",
            })
          );
      }        
    },
    onError: (error) => {
      dispatch(
        showToast({
          message: "Some thing went wrong; \nPlease contact your administrator!",
          variant: "danger",
        })
      );
    },
    onSettled: () => {
      dispatch(setFabricLoading())
      dispatch(setFabricData({}));
    },
  });
  const deleteMutation = useMutation({
    mutationFn: fabric_deleteItem,
    onSuccess: () => {
      queryClient.invalidateQueries(["fabrics"]); // Refetch sizes after deletion
      dispatch(
        showToast({
          message: "Fabric deleted successfully!",
          variant: "success",
        })
      );
    },
    onError: (error) => {      
      dispatch(
        showToast({
          message: "Failed to delete fabric; please try again.",
          variant: "danger",
        })
      );
    },    
  });

  const handleSubmit = (values, { resetForm }) => {       
    showModal({
      title: "Save Confirmation",
      message: "Are you sure you want to save this record?",
      onConfirm: () => {
        mutation.mutate(values, {
          onSuccess: () => {
            resetForm();
          },
        });
      },
      confirmText: "Yes",
      cancelText: "No",
    });
  };

  const handleDelete = (id) => {   
    showModal({
      title: "Delete Confirmation",
      message: "Are you sure you want to delete this fabric?",
      onConfirm: () => {
        deleteMutation.mutate({id:id});
      },
      confirmText: "Yes",
      cancelText: "No",
    });
  };
  const handleEdit = (data) => {        
    dispatch(setFabricData(data));
  };
 
  const filteredFabrics = fabrics.filter((element) =>
    element.fabricname.toLowerCase().includes(searchTerm.toLowerCase())
  ); 
  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  return (
    <>
      <Modal show={fabricModal} onHide={() =>{ dispatch(setFabricModal(false));dispatch(setFabricData({}));}}>
        <Modal.Header closeButton>
          <h4>Fabric</h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Formik
              initialValues={Object.keys(fabricData).length > 0 ? fabricData 
                :initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                validateForm,
                setFieldTouched,
                errors,
                touched,
                getFieldProps,
              }) => (
                <Form autoComplete="off">
                  <Col sm={12}>
                    <FloatingLabelInput
                      id="fabricname"
                      label="Fabric Name"
                      placeholder=""
                      size="md"
                      {...getFieldProps("fabricname")}
                      error={errors.fabricname}
                      touched={touched.fabricname}
                    />
                  </Col>
                  <Col sm={12} className="d-flex justify-content-end">
                  <ButtonEL
                    type="submit"
                    variant="success"
                    isLoading={fabricLoading}                    
                    loadingText="Saving..."                    
                    icon={<FaSave/>}                    
                  > Save </ButtonEL>
        
                  </Col>
                </Form>
              )}
            </Formik>
          </Row>
          <Row>
            <Col sx={12} className="d-flex justify-content-end pt-2 pb-2">
              <SearchField
                placeholder="Search here..."
                borderRadius="50px"
                className="mserch-input"
                customStyle={{ fontSize: "13.5px" }}
                onSearch={handleSearch}
              />
            </Col>
          </Row>
          <Row>
            <Col sx={12}>
             <div className="scrollable-table" style={{ maxHeight: "250px" }}>
              {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: "250px" }}>
                    <Spinner animation="border" />
                  </div>
                ) : isError ? (
                  <div className="text-danger text-center">Failed to load data.</div>
                ) : fabrics?.length > 0 ? (
                <table className="table table-sm w-100 table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {filteredFabrics?.map((element, index) => {
                      return (
                        <tr className="p-2" key={index}>
                          <td>{element?.fabricname}</td>
                          <td className="d-flex justify-content-end align-items-center gap-2">
                            <FaRegEdit
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleEdit(element)}
                            />
                            <FaTrash
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDelete(element?.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                ): (
                  <div className="text-center">No fabrics available.</div>
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FabricMaster;
