import React, { useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";

import "./product.css";
import FloatingLabelInput from "../GlobalComponent/FloatingLabelInput";
import noImg from "../../Assets/noimage.png";
import { Formik, Form } from "formik";
import MainProductList from "./MainProductList";
import useMainProductConfig from "../../API/Hooks/useMainProductConfig";
import SearchField from "../GlobalComponent/SearchField ";
import { useDispatch, useSelector } from "react-redux";
import { setFabricModal, setSizeModal } from "../../API/Reducer/fabricReducer";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { mainProduct_addItem, mainProduct_deleteItem, mainProduct_fetchItems } from "../../API/Service/mainProductService";
import { setCatgId, setMainProductData, setMainProductLoading } from "../../API/Reducer/mainProductReducer";
import { showToast } from "../../API/Reducer/toastSlice";
import ButtonEL from "../GlobalComponent/Elements/ButtonEL";
import { FaSave } from "react-icons/fa";
import { getImageUrl } from "../../MIS/Global";
import SelectEL from "../GlobalComponent/Elements/SelectEL";
import { useModal } from "../ConfirmationModal/ModalContext";
import { FaArrowsRotate } from "react-icons/fa6";

function MainProduct({mainproducts,isLoading, isError,data}) {
  const queryClient = useQueryClient();
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const { validationSchema, initialValues } = useMainProductConfig();
  const { mainProductLoading,mainProductData,catgid } = useSelector((state) => state.mainproduct);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const selectCatgRef = useRef(null);
  const { showModal } = useModal();
  
  const saveMutation = useMutation({
    mutationFn: mainProduct_addItem,
    onMutate: () => {      
      dispatch(setMainProductLoading())
    },
    onSuccess: (resData) => {      
      queryClient.invalidateQueries(["mainproducts"]);
      if(resData?.code==200 && Object.keys(resData?.data).length>0){
        dispatch(
            showToast({
              message: "Record saved successfully",
              variant: "success",
            })
          );          
      }else if(resData?.code==200 && resData?.data.length<=0){
        dispatch(
            showToast({
              message: `This Product Already Exist`,
              variant: "danger",
            })
          );
      }else if(resData?.code==200 && resData?.data==-1){
        dispatch(
          showToast({
            message: resData?.msg,
            variant: "danger",
          })
        );
      }
      else{
        dispatch(
            showToast({
              message: "something went wrong; Please try again!",
              variant: "danger",
            })
          );
      }        
    },
    onError: (error) => {      
      dispatch(
        showToast({
          message: "Some thing went wrong; \nPlease contact your administrator!",
          variant: "danger",
        })
      );
    },
    onSettled: () => {
      selectCatgRef.current.reset();
      handleSelectChange(0)
      dispatch(setMainProductLoading())
      dispatch(setMainProductData({}));
    },
  });

  const deleteMutation = useMutation({
    mutationFn: mainProduct_deleteItem,
    onSuccess: () => {
      queryClient.invalidateQueries(["mainproducts"]); // Refetch sizes after deletion
      dispatch(
        showToast({
          message: "Product deleted successfully!",
          variant: "success",
        })
      );
    },
    onError: (error) => {      
      dispatch(
        showToast({
          message: "Failed to delete product; please try again.",
          variant: "danger",
        })
      );
    },    
  });

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input's click event
    }
  };
  

   const handleSubmit = (values, { resetForm }) => {         
      const isValid = selectCatgRef.current.validate();
      if (!isValid) {
        return selectCatgRef.current.validate();
      }

      const formData = new FormData();
      let id=null;            
      try {
        values={...values}        
        delete values["imgpath"];      
      } catch (error) {        
      }      
      if (values?.id > 0) {
        id=values?.id;
        formData.append("id", values?.id);
      }       
      if(catgid > 0){        
        formData.append("catgid",catgid);}
      else{formData.append("catgid", values?.catgid);}
      
      formData.append("mpname", values?.mpname);
      formData.append("loginid", values?.loginid);
      if (file) {        
        formData.append("imgpath", file);
      }      

      showModal({
        title: "Save Confirmation",
        message: "Are you sure you want to save this record?",
        onConfirm: () => {
          saveMutation.mutate(formData, {
            onSuccess: () => {
              resetForm();
              setFile(null); 
            },
          });
        },
        confirmText: "Yes",
        cancelText: "No",
      });
      
    };
  
    const handleDelete = (id) => {    
      showModal({
        title: "Delete Confirmation",
        message: "Are you sure you want to delete this product?",
        onConfirm: () => {
          deleteMutation.mutate({id:id});
        },
        confirmText: "Yes",
        cancelText: "No",
      });
    };
    const handleEdit = (data) => {        
      dispatch(setMainProductData(data));
    };
   
    const filteredMainProducts = mainproducts.filter((element) =>
      element.mpname.toLowerCase().includes(searchTerm.toLowerCase())
    ); 
    const handleSearch = (term) => {
      setSearchTerm(term);
    };
    const handleSelectChange = (selectedValue) => {    
      dispatch(setCatgId(selectedValue))    
    };

  return (
    <>
    <Formik 
        initialValues={Object.keys(mainProductData).length > 0 ? mainProductData 
                :initialValues}
              enableReinitialize 
    
    validationSchema={validationSchema} onSubmit={handleSubmit}>
    {({ errors, touched, getFieldProps }) => (
    <Form autoComplete="off">
    <Container fluid className="pt-1">
      <Row>
        <Col sm={8}>
          <Card className="shadow">
            <CardBody>
              <Row className="d-none">
                <Col className="d-flex justify-content-end gap-2">
                  <Button type="button" onClick={() => dispatch(setFabricModal())}>+ Fabric</Button>
                  <Button variant="secondary" onClick={() => dispatch(setSizeModal())}>+ Size</Button>
                </Col>
              </Row>
              <hr className="custom-divider d-none" />
              <Row>
                <Col
                  sm={4}
                  className="d-flex justify-content-center align-items-center position-relative image-container"
                >                                 
                  <Image
                    src={
                      file
                        ? URL.createObjectURL(file)                       
                        : Object.keys(mainProductData).length > 0 ? getImageUrl(`${mainProductData?.imgpath}`)
                        : noImg
                    }
                    rounded
                    className="upload-image"
                    alt={file ? file.name : "No Image"}
                  />

                  {/* Upload button */}
                  <Button type="button" variant="primary" className="upload-btn" onClick={handleButtonClick}>
                    Upload File
                  </Button>

                  {/* Hidden file input */}
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file-upload"
                  />
                </Col>
                <Col sm={8} className="d-flex flex-column gap-4">                 
                  <SelectEL
                    ref={selectCatgRef}
                    onChange={handleSelectChange}
                    options={data?.categories}
                    defaultText="Choose an category"
                    size="md"
                    keyName="catgname"
                    keyId="id"
                    selectedValue={mainProductData?.catgid || 0}
                    required={true}
                    errorText="Please select an product category"
                  />
                
                  <FloatingLabelInput
                    id="mpname"
                    label="Product Name"
                    placeholder=""                    
                    {...getFieldProps("mpname")}
                    error={errors.mpname}
                    touched={touched.mpname}
                    size="md"                        
                  />
                </Col>
              </Row>
              <Row>
              <Col className="d-flex justify-content-end gap-2">
              <ButtonEL
                  type="button"
                  variant="success"
                  isLoading={false}
                  loadingText="Saving..."
                  icon={<FaArrowsRotate className="icon"/>}
                  onClick={data?.resetForm}
                  className="reset-button"
                >
                  Reset
                </ButtonEL>
              <ButtonEL
                    type="submit"
                    variant="success"
                    isLoading={mainProductLoading}       
                    loadingText="Saving..."                    
                    icon={<FaSave/>}
                  > Save </ButtonEL> 
              </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm={4}>
          <Card className="shadow">
            <CardBody>
               <Row>
                <Col className="d-flex justify-content-end align-items-center mb-1">
                 <SearchField
                  placeholder="Search here..."
                  borderRadius="50px"
                  className="mserch-input"
                  customStyle={{fontSize:'13.5px'}}  
                  onSearch={handleSearch}  
                 />
                </Col>
                 <hr/>
                <Col sm={12} className="w-100">
                  <MainProductList
                   data={filteredMainProducts}
                   onEdit={handleEdit} 
                   onDelete={handleDelete}
                   isLoading={isLoading}
                   isError={isError}
                   />
                </Col>
                <Col></Col>
               </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
      </Form>
      )}
    </Formik>
   
    </>
  );
}

export default MainProduct;
