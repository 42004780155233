import * as Yup from "yup";

const useFabricConfig = () => {
  const validationSchema = Yup.object({
    fabricname: Yup.string().required("Fabric name is required"),    
  });

  const initialValues = {
    id:0,
    fabricname: "",
    fabric_price: 0,
    fabric_status:0,
    loginid:0,
  };

  return { validationSchema, initialValues };
};

export default useFabricConfig;
