import React from "react";
import "./FloatingLabelInput.css";

const FloatingLabelInput = ({
  type,
  id,
  label,
  value,
  onChange,
  onBlur,
  size = "md",
  placeholder = " ",
  error,
  touched,
}) => {
  return (
    <div className={`floating-label-input ${size} ${error && touched ? "has-error" : ""}`}>
      <input
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className="input-field"
        placeholder={placeholder} /* Placeholder for floating label */
      />
      <label htmlFor={id} className="input-label">
        {label}
      </label>
      {error && touched && <div className="error-message">{error}</div>}
    </div>
  );
};

export default FloatingLabelInput;
