import React, { useRef, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import SelectEL from "../../GlobalComponent/Elements/SelectEL";
import noImg from "../../../Assets/noimage.png";
import "../../../Components/pages/product.css";
import { useDispatch, useSelector } from "react-redux";
import { setTelMpId, setTelSubId } from "../../../API/Reducer/mainProductReducer";
import { FaSave, FaTrash } from "react-icons/fa";
import ButtonEL from "../../GlobalComponent/Elements/ButtonEL";
import { Formik, Form } from "formik";
import { useModal } from "../../ConfirmationModal/ModalContext";
import useToast from "../../../API/GlobalHooks/useToast";
import { useMutation } from "@tanstack/react-query";
import { setTelProductLoading } from "../../../API/Reducer/productReducer";
import { FaArrowsRotate } from "react-icons/fa6";
import useTelProductConfig from "../../../API/Hooks/useTelProductConfig";
import { telProduct_addItem } from "../../../API/Service/telProductService";

const TelProductForm = ({ data }) => {
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const selectMPref = useRef(null);
  const selectSUBref = useRef(null);
  const { validationSchema, initialValues } = useTelProductConfig();

  const dispatch = useDispatch();
  const { tel_mpid,tel_subid } = useSelector((state) => state.mainproduct);
  const { telProductLoading, telProductData } = useSelector(
    (state) => state.product
  );
  const { showModal } = useModal();
  const showToast = useToast();


  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input's click event
    }
  };

  const handleDeleteImage = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleMainProductSelectChange = (selectedValue) => {
    dispatch(setTelMpId(selectedValue));
  };
  const handleSubProductSelectChange = (selectedValue) => {
    dispatch(setTelSubId(selectedValue));
  };
  const saveMutation = useMutation({
    mutationFn: telProduct_addItem,
    onMutate: () => {      
      dispatch(setTelProductLoading())
    },
    onSuccess: (resData) => {            
      if(resData?.code==200 && Object.keys(resData?.data).length>0){
        showToast('Record saved successfully', 'success');         
      }else if(resData?.code==200 && resData?.data==-1){        
        showToast(resData?.msg, 'danger');        
      }
      else{
        showToast("something went wrong; Please try again!", 'danger');       
      }        
    },
    onError: (error) => {      
      showToast("Some thing went wrong; \nPlease contact your administrator!", 'danger');     
    },
    onSettled: () => {
      selectMPref.current.reset();
      selectSUBref.current.reset();
      handleMainProductSelectChange(0)
      handleSubProductSelectChange(0)
      dispatch(setTelProductLoading())      
    },
  });
  const handleSubmit=(values, { resetForm })=>{
    const isValidMP = selectMPref.current.validate();
      if (!isValidMP) {
        return selectMPref.current.validate();
      }
    const isValidSub = selectSUBref.current.validate();
      if (!isValidSub) {
        return selectSUBref.current.validate();
      }
    const formData = new FormData();
    formData.append("mpid",tel_mpid);
    formData.append("subid",tel_subid);
    formData.append("loginid", values?.loginid);
    if (files) {              
      files.forEach((file) => {
        formData.append("imgpath", file); // Use the same key for each image
      });
    }
    showModal({
      title: "Save Confirmation",
      message: "Are you sure you want to save this record?",
      onConfirm: () => {
        saveMutation.mutate(formData, {
          onSuccess: () => {
            resetForm();
            setFiles([]); 
          },
        });
      },
      confirmText: "Yes",
      cancelText: "No",
    });
  }

   const handleResetForm=()=>{
    selectMPref.current.reset();
    selectSUBref.current.reset();
    data?.resetForm();
   }

  return (
    <>
    <Formik 
      initialValues={Object.keys(telProductData).length > 0 ? telProductData 
                :initialValues}
      enableReinitialize
      validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ errors, touched, getFieldProps }) => (
      <Form autoComplete="off">
        <Container fluid className="p-1">
          <Row>
            <Col sm={12} className="pb-1 gap-2 d-flex justify-content-end">
            <ButtonEL
              type="button"
              variant="success"
              isLoading={false}
              loadingText="Saving..."
              icon={<FaArrowsRotate className="icon"/>}
              onClick={handleResetForm}
              className="reset-button"
            >
              Reset
            </ButtonEL>
              <ButtonEL
                type="submit"
                variant="success"
                isLoading={telProductLoading}
                loadingText="Saving..."
                icon={<FaSave />}
              >            
                Save
              </ButtonEL>
            </Col>
            <Col
              sm={4}
              className="d-flex justify-content-center align-items-center position-relative image-container"
            >
              <Image
                src={noImg}
                rounded
                className="upload-placeholder"
                alt="Placeholder"
              />
              <Button
                type="button"
                variant="primary"
                className="upload-btn mt-3"
                onClick={handleButtonClick}
              >
                Upload Images
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="file-upload"
                multiple
                accept="image/*"
              />
            </Col>
            {/* Form Section */}
            <Col sm={8} className="d-flex flex-column gap-3">
              <SelectEL
                ref={selectMPref}
                onChange={handleMainProductSelectChange}
                options={data?.mainproducts}
                defaultText="Choose an Main Product"
                size="md"
                keyName="mpname"
                keyId="id"
                selectedValue={telProductData?.mpid || 0}
                required={true}
                errorText="Please select an main product"
              />
              <SelectEL
                ref={selectSUBref}
                onChange={handleSubProductSelectChange}
                options={data?.subProducts}
                defaultText="Choose an Sub Product"
                size="md"
                keyName="subpname"
                keyId="id"
                selectedValue={telProductData?.subid || 0}
                required={true}
                errorText="Please select an sub product"
              />
            </Col>

            {/* Image List Section */}
            <Col sm={12} className="mt-4">
              {/* <h5>Image List</h5> */}
              <div className="image-list-container">
                {files?.map((file, index) => (
                  <div className="image-box" key={index}>
                    <Image
                      src={URL.createObjectURL(file)}
                      className="uploaded-image"
                      alt={file.name}
                    />
                    <div
                      className="delete-icon"
                      onClick={() => handleDeleteImage(index)}
                    >
                      <FaTrash />
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
      )}
    </Formik>
    </>
  );
};

export default TelProductForm;
