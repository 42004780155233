import { Route, Routes } from "react-router-dom";
import RootLayout from "./Components/Layout/RootLayout";
import { Home } from "./Components/pages/ImportPages";
import DemoPage from "../src/Components/Demo/DemoPage";
import AppAdminLayout from "./Components/Layout/AppAdminLayout";
import AdminContent from "./Components/pages/AdminContent";
import GlobalToast from "./Components/loader/GlobalToast";
function App() {
  return (
    <>
    <GlobalToast/>
    <Routes>
      
      {/* This is user layout routes */}
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Home />} />
        <Route path="/demo" element={<DemoPage />} />
      </Route>
      <Route path="/admin" element={<AppAdminLayout />}>
        <Route index element={<AdminContent />} />
      </Route>
    </Routes>
    </>
  );
}

export default App;
