import * as Yup from "yup";

const useSubProductConfig = () => {
  const validationSchema = Yup.object({
    subpname: Yup.string().required("Size name is required")      
  });

  const initialValues = {
    id:0,    
    subpname: "",
    imgpath:"",
    subp_descp:"",
    subp_status: 0,
    mpid:"",
    loginid:0,
  };

  return { validationSchema, initialValues };
};

export default useSubProductConfig;
