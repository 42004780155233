import React from "react";
import { Container, Navbar, Offcanvas } from "react-bootstrap";
import "../../AdminLayout.css";
import { FaCircleUser } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { setFabricModal, setSizeModal } from "../../API/Reducer/fabricReducer";
import { useDispatch } from "react-redux";
import FabricMaster from "../../Components/Fabric/FabricMaster"
import SizeMaster from "../../Components/Fabric/SizeMaster"
import { setProductCatgModal } from "../../API/Reducer/productCategoryReducer";
import CategoryPage from "../Admin/Category.jsx/CategoryPage";
function AdminNavbar() {
  const dispatch = useDispatch();
  return (
    <>
      <Navbar bg="warning" expand={false} className="admin-layout">
        <Container fluid>
          <Navbar.Toggle aria-controls="offcanvasNavbar" />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"            
            style={{ width: "250px" }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                Teeblanka 
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
             <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
             <li class="nav-item"
              onClick={() => dispatch(setProductCatgModal())}
              >
                <span class="nav-link active pointer" aria-current="page">Category</span>
              </li>
              <li class="nav-item" onClick={() => dispatch(setFabricModal())}>
                <span class="nav-link active pointer" aria-current="page">Fabric</span>
              </li>
              <li class="nav-item"
              onClick={() => dispatch(setSizeModal())}
              >
                <span class="nav-link active pointer" aria-current="page">Size</span>
              </li>
             
              
             </ul>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <FaCircleUser />
        </Container>
      </Navbar>
      <FabricMaster/>
      <SizeMaster/>
      <CategoryPage/>
    </>
  );
}

export default AdminNavbar;
