import { createSlice } from "@reduxjs/toolkit";
 
 
const mainProductReducer = createSlice({
  name: "mainproduct",
  initialState: {     
    mainProductLoading:false,    
    mainProductData:{},
    subProductLoading:false,    
    subProductData:{},
    mpid:0,
    catgid:0,
    subid:0,
    tel_mpid:0,    
    tel_subid:0,
    subDescription:{},
  },

  reducers: {          
    setMainProductLoading(state, action) {      
      state.mainProductLoading = !state.mainProductLoading
    },    
    setMainProductData(state,action){
      state.mainProductData=action.payload
    },
    setSubProductLoading(state, action) {      
      state.subProductLoading = !state.subProductLoading
    },    
    setSubProductData(state,action){
      state.subProductData=action.payload
    },
    setMpId(state,action){
      state.mpid=action.payload
    },
    setCatgId(state,action){
      state.catgid=action.payload
    }, 
    setSubId(state,action){
      state.subid=action.payload
    }, 
    setTelMpId(state,action){
      state.tel_mpid=action.payload
    },   
    setTelSubId(state,action){
      state.tel_subid=action.payload
    },    
    setSubDescription(state,action){
      state.subDescription=action.payload
    },
    
  },
});

export const {
    setMainProductLoading,setMainProductData,setSubProductLoading,setSubProductData,
    setMpId,setCatgId,setSubDescription,setSubId,setTelMpId,setTelSubId
} = mainProductReducer.actions;
export default mainProductReducer.reducer;
