import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationModal = ({ 
  show, 
  onHide, 
  title = "Confirmation", 
  message = "Are you sure?", 
  onConfirm, 
  confirmText = "Yes", 
  cancelText = "No", 
  size="sm"
}) => {
  return (
    <Modal show={show} onHide={onHide} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {cancelText}
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
