import { createSlice } from "@reduxjs/toolkit";
 
 
const fabricReducer = createSlice({
  name: "fabric",
  initialState: { 
    fabricModal: false,   
    sizeModal:false,
    fabricLoading:false,
    sizeLoading:false,
    sizeData:{},
    fabricData:{},
    sizeSelectedList:[],
    fabricSelectedList:[],
    fabricDataLoading:false,
    sizeDataLoading:false,
    fabricDataError:false,
    sizeDataError:false,

  },

  reducers: {       
    setFabricModal(state, action) {      
      state.fabricModal = !state.fabricModal
    },
    setSizeModal(state, action) {      
      state.sizeModal = !state.sizeModal
    },
    setSizeLoading(state, action) {      
      state.sizeLoading = !state.sizeLoading
    },
    setFabricLoading(state, action) {      
      state.fabricLoading = !state.fabricLoading
    }, 
    setSizeData(state,action){
      state.sizeData=action.payload
    },
    setFabricData(state,action){
      state.fabricData=action.payload
    },
    setFabricSelectedList(state,action){
      state.fabricSelectedList=action.payload
    },    
    setSizeSelectedList(state,action){
      state.sizeSelectedList=action.payload
    },
  
  },
});

export const {
    setFabricModal,setSizeModal,setSizeLoading,setFabricLoading,
    setSizeData,setFabricData,setSizeSelectedList,setFabricSelectedList,     
} = fabricReducer.actions;
export default fabricReducer.reducer;

 


