
import axios from "axios";
import { getUrl } from "../../MIS/Global";


// Axios instance for making API calls (you can configure it as needed)
const apiClient = axios.create({
  baseURL: getUrl(),  // Replace with your API base URL
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

// Fetch all items (GET request)
export const tbkProduct_fetchItems = async (newData) => {  
  if(newData?.mpid <= 0 || newData?.subid <= 0){  
    return [];  
  }
   
  let data={page:1,limit:1000,mpid:newData?.mpid,subid:newData?.subid}
  const queryParams = new URLSearchParams(data).toString();
  const response = await apiClient.get(`tbk-product/?${queryParams}`);  
  return response?.data?.data;
};

// Add an item (POST request)
export const tbkProduct_addItem = async (newItem) => {      
  if(newItem.get("id") > 0){    
    const response = await apiClient.patch("tbk-product/", newItem);  
    return response.data;
  }else{    
    delete newItem.id      
    const response = await apiClient.post("tbk-product/", newItem);  
    return response.data;
  }
  
};

// Delete an item (DELETE request)
export const tbkProduct_deleteItem = async (newItem) => {    
  const queryParams = new URLSearchParams(newItem).toString();
  const response = await apiClient.delete(`tbk-product/?${queryParams}`);
  return response.data;
};
