import * as Yup from "yup";

const useTelProductConfig = () => {
  const validationSchema = Yup.object({   
  });

  const initialValues = {
    id:0,    
    mpid:0,
    subid:0,
    productname:"",
    imgpath:"",    
    prod_status: 0,
    prod_publish: 0,    
    loginid:0,
  };

  return { validationSchema, initialValues };
};

export default useTelProductConfig;
