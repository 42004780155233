import React from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { useSelector, useDispatch } from "react-redux";
import { hideToast } from "../../API/Reducer/toastSlice";


const GlobalToast = () => {
  const dispatch = useDispatch();
  const { show, message, variant } = useSelector((state) => state.toast); // Get toast state from Redux

  return (
    <ToastContainer position="top-center" className="p-3">
      <Toast
        show={show}
        onClose={() => dispatch(hideToast())}
        bg={variant || "light"} // Supports Bootstrap variants like "success", "danger", etc.
        delay={3000}
        autohide
      >
        <Toast.Header closeButton>
          <strong className="me-auto">Message</strong>
        </Toast.Header>
        <Toast.Body style={{color:'#fff'}}>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default GlobalToast;
