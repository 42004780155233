import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import './SizeListCheckbox.css'; // Add CSS for better visibility
import { setFabricSelectedList } from '../../../API/Reducer/fabricReducer';


const FabricListCheckbox = () => {
    const dispatch=useDispatch(); 
    const { fabricSelectedList } = useSelector((state) => state.fabric);
 
    const handleCheckboxChange = (id) => {          
        const updatedItems = fabricSelectedList.map((item) =>
            item.id === id ? { ...item, 
                is_check: item?.is_check===false?true:true } : item
        );
        dispatch(setFabricSelectedList(updatedItems));
     };
    return (
        <Container fluid style={{ fontSize: '16px' }}>
            <Row
                className="gy-3 scrollable-row"
                style={{
                    maxHeight: '300px', // Set maximum height
                    overflowY: 'auto', // Enable vertical scrolling
                    padding: '10px', // Compact padding
                }}
            >
                {fabricSelectedList?.map((element) => (
                    <Col key={element?.id} xs={3} className="d-flex align-items-center">
                        <div className="form-check custom-checkbox">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id={`flexCheck${element?.id}`}
                                checked={element?.is_check}
                                onChange={(e) => {                              
                                handleCheckboxChange(element?.id)}}
                            />
                            <label
                                className="form-check-label ms-2"
                                htmlFor={`flexCheck${element?.id}`}
                            >
                                {element?.fabricname}
                            </label>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default FabricListCheckbox;
