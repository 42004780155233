import * as Yup from "yup";

const useMainProductConfig = () => {
  const validationSchema = Yup.object({
    mpname: Yup.string().required("Product name is required"),    
  });

  const initialValues = {
    id:0,
    mpname: "",
    imgpath: "",
    loginid:0,
  };

  return { validationSchema, initialValues };
};

export default useMainProductConfig;
