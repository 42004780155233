import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FloatingLabelInput from "../GlobalComponent/FloatingLabelInput";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setSizeData, setSizeLoading, setSizeModal } from "../../API/Reducer/fabricReducer";
import { FaRegEdit, FaSave, FaTrash } from "react-icons/fa";
import SearchField from "../GlobalComponent/SearchField ";
import useSizeConfig from "../../API/Hooks/useSizeConfig";
import { useMutation, useQuery,useQueryClient } from "@tanstack/react-query";
import { size_addItem, size_deleteItem, size_fetchItems } from "../../API/Service/sizeService";
import { showToast } from "../../API/Reducer/toastSlice";
import ButtonEL from "../GlobalComponent/Elements/ButtonEL";
import { useModal } from "../ConfirmationModal/ModalContext";

const SizeMaster = () => {
  const queryClient = useQueryClient();
  const { validationSchema, initialValues } = useSizeConfig();
  const dispatch = useDispatch();
  const { sizeModal,sizeLoading,sizeData } = useSelector((state) => state.fabric);
  const [searchTerm, setSearchTerm] = useState("");
  const { showModal } = useModal();
  
  const { data: sizes=[], isLoading, isError } = useQuery({
    queryKey: ["sizes"],
    queryFn: size_fetchItems,
    onSuccess: (data) => {      
      // console.log("Fetched sizes data:", data);
    },
  });

  const mutation = useMutation({
    mutationFn: size_addItem,
    onMutate: () => {      
      dispatch(setSizeLoading())
    },
    onSuccess: (resData) => {      
      queryClient.invalidateQueries(["sizes"]);
      if(resData?.code==200 && Object.keys(resData?.data).length>0){
        dispatch(
            showToast({
              message: "Record saved successfully",
              variant: "success",
            })
          );          
      }else if(resData?.code==200 && resData?.data.length<=0){
        dispatch(
            showToast({
              message: `This Size Already Exist`,
              variant: "danger",
            })
          );
      }else{
        dispatch(
            showToast({
              message: "something went wrong; Please try again!",
              variant: "danger",
            })
          );
      }        
    },
    onError: (error) => {
      dispatch(
        showToast({
          message: "Some thing went wrong; \nPlease contact your administrator!",
          variant: "danger",
        })
      );
    },
    onSettled: () => {
      dispatch(setSizeLoading())
      dispatch(setSizeData({}));
    },
  });

  const deleteMutation = useMutation({
    mutationFn: size_deleteItem,
    onSuccess: () => {
      queryClient.invalidateQueries(["sizes"]); // Refetch sizes after deletion
      dispatch(
        showToast({
          message: "Size deleted successfully!",
          variant: "success",
        })
      );
    },
    onError: (error) => {      
      dispatch(
        showToast({
          message: "Failed to delete size; please try again.",
          variant: "danger",
        })
      );
    },    
  });
  const handleSubmit = (values, { resetForm }) => {   
    showModal({
      title: "Save Confirmation",
      message: "Are you sure you want to save this record?",
      onConfirm: () => {
        mutation.mutate(values, {
          onSuccess: () => {
            resetForm();
          },
        });
      },
      confirmText: "Yes",
      cancelText: "No",
    }); 
   
  };
  const handleDelete = (id) => {    
    showModal({
      title: "Delete Confirmation",
      message: "Are you sure you want to delete this size?",
      onConfirm: () => {
        deleteMutation.mutate({id:id});
      },
      confirmText: "Yes",
      cancelText: "No",
    });
  };
  const handleEdit = (size) => {        
    dispatch(setSizeData(size));
  };
 
  const filteredSizes = sizes.filter((size) =>
    size.sizename.toLowerCase().includes(searchTerm.toLowerCase())
  ); 
  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  return (
    <>
      <Modal show={sizeModal} onHide={() => {dispatch(setSizeModal(false));dispatch(setSizeData({}));}}>
        <Modal.Header closeButton>
          <h4>Size</h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Formik
              initialValues={
                Object.keys(sizeData).length > 0 ? sizeData 
                :initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                validateForm,
                setFieldTouched,
                errors,
                touched,
                getFieldProps,
              }) => (
                <Form autoComplete="off">
                  <Col sm={12}>
                    <FloatingLabelInput
                      id="sizename"
                      label="Size Name"
                      placeholder=""
                      size="md"
                      {...getFieldProps("sizename")}
                      error={errors.sizename}
                      touched={touched.sizename}
                    />
                  </Col>
                  <Col sm={12} className="d-flex justify-content-end">
                  <ButtonEL
                    type="submit"
                    variant="success"
                    isLoading={sizeLoading}                    
                    loadingText="Saving..."                    
                    icon={<FaSave/>}
                  > Save </ButtonEL>                   
                  </Col>
                </Form>
              )}
            </Formik>
          </Row>
          <Row>
            <Col sx={12} className="d-flex justify-content-end pt-2 pb-2">
              <SearchField
                placeholder="Search here..."
                borderRadius="50px"
                className="mserch-input"
                customStyle={{ fontSize: "13.5px" }}
                onSearch={handleSearch}
              />
            </Col>
          </Row>
          <Row>
            <Col sx={12}>
              <div className="scrollable-table" style={{ maxHeight: "250px" }}>
              {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: "250px" }}>
                    <Spinner animation="border" />
                  </div>
                ) : isError ? (
                  <div className="text-danger text-center">Failed to load data.</div>
                ) : sizes?.length > 0 ? (
                <table className="table table-sm w-100 table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {filteredSizes?.map((element, index) => {
                      return (
                        <tr className="p-2" key={index}>
                          <td>{element?.sizename}</td>
                          <td className="d-flex justify-content-end align-items-center gap-2">
                            <FaRegEdit
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleEdit(element)}
                            />
                            <FaTrash
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDelete(element?.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                ): (
                  <div className="text-center">No sizes available.</div>
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SizeMaster;
