import React, { useState, useEffect, useRef } from "react";
import { Container, Button } from "react-bootstrap";
import { setSubDescription } from "../../../API/Reducer/mainProductReducer";
import { useDispatch, useSelector } from "react-redux";

const DescpTable = () => {
  const { subDescription } = useSelector((state) => state.mainproduct);
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [editingRowId, setEditingRowId] = useState(null); // Track which row is being edited

  // Initialize local state with Redux state
  useEffect(() => {
    if (subDescription && subDescription.length > 0) {
      setRows(subDescription);
    } else {
      setRows([{ id: Date.now(), description: "", isEditing: false }]);
    }
  }, [subDescription]);

  // Handle input change for a specific row
  const handleInputChange = (id, value) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, description: value } : row
      )
    );
  };

  // Enter edit mode
  const handleEnterEditMode = (id) => {
    setEditingRowId(id); // Set the currently editing row
  };

  // Exit edit mode
  const handleExitEditMode = (id) => {
    if (editingRowId === id) {
      setEditingRowId(null); // Clear editing state
      dispatch(setSubDescription(rows)); // Save changes to Redux
    }
  };

  // Add a new row
  const handleAddRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      { id: Date.now(), description: "", isEditing: false },
    ]);
  };

  // Delete a row (only if more than one row is present)
  const handleDeleteRow = (id) => {
    if (rows.length > 1) {
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
      dispatch(setSubDescription(updatedRows));
    }
  };

  return (
    <Container>
      <div className="table-responsive pt-2">
        <div>
          <table
            className="table table-sm w-100"
            style={{
              fontSize: "13.5px",
              tableLayout: "fixed",
            }}
          >
            <thead>
              <tr>
                <th style={{ width: "80%" }}>Description</th>
                <th style={{ width: "20%", textAlign: "center" }}>Actions</th>
              </tr>
            </thead>
            <tbody className="list">
              {rows.map((row) => (
                <tr key={row.id}>
                  <td style={{ wordWrap: "break-word" }}>
                    {editingRowId === row.id ? (
                      <textarea
                        value={row.description}
                        onChange={(e) =>
                          handleInputChange(row.id, e.target.value)
                        }
                        onBlur={() => handleExitEditMode(row.id)} // Exit edit mode on blur
                        className="form-control form-control-sm"
                        style={{
                          width: "100%",
                          minHeight: "50px",
                          resize: "none",
                        }}
                        autoFocus
                      />
                    ) : (
                      <div
                        onClick={() => handleEnterEditMode(row.id)} // Enter edit mode on click
                        style={{
                          cursor: "pointer",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                        }}
                      >
                        {row.description || "Click to add description"}
                      </div>
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{
                        padding: "0.25rem 0.5rem",
                        fontSize: "10px",
                      }}
                      onClick={handleAddRow}
                    >
                      Add
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      style={{
                        padding: "0.25rem 0.5rem",
                        fontSize: "10px",
                      }}
                      onClick={() => handleDeleteRow(row.id)}
                      disabled={rows.length === 1} // Disable delete button if only one row
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default DescpTable;
