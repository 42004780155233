import React, { useState } from "react";
import PropTypes from "prop-types";
import "./SearchField.css";
import { FaSearch,FaTimes } from "react-icons/fa";



const SearchField = ({ placeholder, borderRadius,className="", onSearch,customStyle = {} }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleClear = () => {
    setSearchTerm("");
    if (onSearch) onSearch(""); // Notify parent of cleared input
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    if (onSearch) onSearch(e.target.value); // Notify parent of change
  };

  return (
    <div      
      className={`search-field ${className || ""}`}
      style={{ borderRadius: borderRadius || "5px" }}
    >
      <span className="search-icon"><FaSearch/></span>
      <input
        type="text"
        className={`search-input`}
        placeholder={placeholder || "Search..."}
        value={searchTerm}
        onChange={handleChange}    
        style={customStyle}    
      />
      {searchTerm && (
        <FaTimes
          className="clear-icon"
          onClick={handleClear}
          
        />
      )}
    </div>
  );
};

SearchField.propTypes = {
  placeholder: PropTypes.string,
  borderRadius: PropTypes.string,
  onSearch: PropTypes.func,
};

export default SearchField;
