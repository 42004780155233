import React from "react";
import { Card, CardBody, Col, Container, Row } from "react-bootstrap";
import ProductForm from "./ProductForm";
import TbkProductList from "./TbkProductList";
import TelProductList from "./TelProductList";
import TelProductForm from "./TelProductForm";

const ProductPage = ({ data,isType }) => {
  return (
    <>
      <Container fluid className="p-2">
        <Row>
          <Col sm={7}>
            <Card className="shadow">
              <CardBody>
              {
                isType=="tbk"?
                (<ProductForm data={data} />)
                :(<TelProductForm data={data}/>)
              }
                
              </CardBody>
            </Card>
          </Col>
          <Col sm={5}>
            <Card className="shadow">
              <CardBody>
              {
                isType=="tbk"?
                (<TbkProductList data={data}/>)
                :(<TelProductList data={data}/>)
              }
                
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProductPage;
