import React, { useRef } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import noImg from "../../../Assets/noimage.png";
import { useDispatch, useSelector } from "react-redux";
import { getImageUrl } from "../../../MIS/Global";
import FloatingLabelInput from "../../GlobalComponent/FloatingLabelInput";
import SelectEL from "../../GlobalComponent/Elements/SelectEL";
import { setMpId } from "../../../API/Reducer/mainProductReducer";
import DescpTable from "./DescpTable";

const SubProductForm = ({
  file,
  fileInputRef,
  handleFileChange,
  handleButtonClick,
  errors,
  getFieldProps,
  touched,
  selectRef,mainproducts
}) => {
  const { subProductData } = useSelector((state) => state.mainproduct);  
  const dispatch=useDispatch();  

  const handleSelectChange = (selectedValue) => {    
    dispatch(setMpId(selectedValue))    
  };
  return (
    <>
      <Container>
        <Row>
          <Col
            sm={4}
            className="d-flex justify-content-center align-items-center position-relative image-container"
          >
            {/* Display the image or default icon */}
            <Image
              src={
                file
                  ? URL.createObjectURL(file)
                  : Object.keys(subProductData).length > 0
                  ? getImageUrl(`${subProductData?.imgpath}`)
                  : noImg
              }
              rounded
              className="upload-image"
              alt={file ? file.name : "No Image"}
            />

            {/* Upload button */}
            <Button
              type="button"
              variant="primary"
              className="upload-btn"
              onClick={handleButtonClick}
            >
              Upload File
            </Button>

            {/* Hidden file input */}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-upload"
            />
          </Col>
          <Col sm={8}>
            <Row>
            <Col sm={12} className="pb-1">
                  <SelectEL
                    ref={selectRef}
                    onChange={handleSelectChange}
                    options={mainproducts}
                    defaultText="Choose an Main Product"
                    size="md"
                    keyName="mpname"
                    keyId="id"
                    selectedValue={subProductData?.mpid || 0}
                    required={true}
                    errorText="Please select an Main Product"
                  />
                </Col>
              <Col sm={12} className="pt-2">
                <FloatingLabelInput                
                  id="subpname"
                  label="SubProduct Name"
                  placeholder=""
                  {...getFieldProps("subpname")}
                  error={errors.subpname}
                  touched={touched.subpname}
                  size="md"
                />
              </Col>
               
                <Col sm={12}>
                  <DescpTable/>
                </Col>
              
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SubProductForm;
