import React, { useEffect, useState } from "react";
import { Card, Col, Nav, Row } from "react-bootstrap";
import MainProduct from "./MainProduct";
import SubProduct from "../Admin/SubProduct/SubProduct";
import { useQuery } from "@tanstack/react-query";
import { mainProduct_fetchItems } from "../../API/Service/mainProductService";
import { fabric_fetchItems } from "../../API/Service/fabricService";
import { useDispatch, useSelector } from "react-redux";
import { size_fetchItems } from "../../API/Service/sizeService";
import { productCatg_fetchItems } from "../../API/Service/productCategoryService";
import { setFabricSelectedList, setSizeSelectedList } from "../../API/Reducer/fabricReducer";
import { subProduct_fetchItems } from "../../API/Service/subProductService";
import ProductPage from "../Admin/Product/ProductPage";
import { tbkProduct_fetchItems } from "../../API/Service/tbkProductService";
import { setCatgId, setMainProductData, setMpId, setSubDescription, setSubId, setSubProductData, setTelMpId, setTelSubId } from "../../API/Reducer/mainProductReducer";
import { telProduct_fetchItems } from "../../API/Service/telProductService";

function MainTabs() {
  const [activeTab, setActiveTab] = useState("Main Product");
  const dispatch=useDispatch();
  const { mpid,subid,subProductData,tel_mpid,tel_subid } = useSelector((state) => state.mainproduct);
  
  const resetForm=()=>{
    dispatch(setMainProductData({}))
    dispatch(setSubProductData({}))
    dispatch(setSubDescription([]))
    dispatch(setMpId(0))
    dispatch(setSubId(0))
    dispatch(setCatgId(0))
    dispatch(setTelMpId(0))
    dispatch(setTelSubId(0))
  }

  const { data: mainproducts=[], isLoading, isError } = useQuery({
    queryKey: ["mainproducts"],
    queryFn: mainProduct_fetchItems,
    onSuccess: (data) => {      
      // console.log("Fetched sizes data:", data);
    },
  });  

  const { data: rawFabrics=[], isLoadingFabric, isErrorFabric } = useQuery({
    queryKey: ["fabrics"],
    queryFn: fabric_fetchItems,         
  });

  const fabrics = rawFabrics.map((item) => ({
    ...item,
    is_check:false,
  }));

  const { data: rawSizes = [], isSizeLoading, isSizeError } = useQuery({
    queryKey: ["sizes"],
    queryFn: size_fetchItems,
    refetchOnWindowFocus: false,     
  });
  
  const sizes = rawSizes.map((size) => ({
    ...size,
    ps_price: 0,
    ps_mrp: 0,
    ps_whlprice: 0,
    is_check:false,
  }));
  
  const { data: categories=[],isCatgLoading,isCatgError} = useQuery({
    queryKey: ["categories"],
    queryFn: productCatg_fetchItems,  
  });

  const { data: subProducts=[], isLoadingSubProduct, isErrorSubProduct } = useQuery({
    queryKey: ["subProducts"],
    queryFn: subProduct_fetchItems,         
  });

  const { data: tbkProducts=[], isLoadingTbkProducts, isErrorTbkProducts } = useQuery({
    queryKey: ["tbkProducts",{ mpid, subid }],    
    queryFn: () => tbkProduct_fetchItems({mpid:mpid,subid:subid}),        
  });

  const { data: telProducts=[], isLoadingTelProducts, isErrorTelProducts } = useQuery({
    queryKey: ["telProducts",{ tel_mpid, tel_subid }],    
    queryFn: () => telProduct_fetchItems({mpid:tel_mpid,subid:tel_subid}),        
  });


  let data={
    fabrics:fabrics,
    isLoadingFabric:isLoadingFabric,
    isErrorFabric:isErrorFabric,
    sizes:sizes,
    isSizeLoading:isSizeLoading,
    isSizeError:isSizeError,
    categories:categories,
    isCatgLoading:isCatgLoading,
    isCatgError:isCatgError,
    mainproducts:mainproducts, 
    isLoading:isLoading, 
    isError:isError,
    subProducts:subProducts, 
    isLoadingSubProduct:isLoadingSubProduct, 
    isErrorSubProduct:isErrorSubProduct,    
    tbkProducts:tbkProducts,
    isLoadingTbkProducts:isLoadingTbkProducts,
    isErrorTbkProducts:isErrorTbkProducts,
    telProducts:telProducts, 
    isLoadingTelProducts:isLoadingTelProducts, 
    isErrorTelProducts:isErrorTelProducts,
    resetForm:resetForm,

  }

  useEffect(() => {
    if(Object.keys(subProductData).length > 0){
      return;
    }
    dispatch(setSizeSelectedList(sizes))    
  }, [sizes])
  useEffect(() => {
    if(Object.keys(subProductData).length > 0){
      return;
    }
    dispatch(setFabricSelectedList(fabrics))
  }, [fabrics])
  
 
  const renderContent = () => {
    switch (activeTab) {
      case "Main Product":
        return <MainProduct mainproducts={mainproducts} isLoading={isLoading} isError={isError} data={data}/>;
      case "Sub Product":
        return <SubProduct mainproducts={mainproducts} data={data}
        />;
      case "TbkProduct":
        return <ProductPage data={data} isType="tbk"/>;
      case "TelProduct":
          return <ProductPage data={data} isType="tel"/>;
      default:
        return null;
    }
  };

  return (
    <div>
      <Card className="mt-2 bg-primary">
        <Row>
          <Nav
            variant="underline"
            activeKey={activeTab}
            onSelect={(eventKey) => setActiveTab(eventKey)}
            onClick={(e)=>resetForm()}
          >
            <Col className="text-center">
              <Nav.Item>
                <Nav.Link eventKey="Main Product">Main Product</Nav.Link>
              </Nav.Item>
            </Col>
            <Col className="text-center">
              <Nav.Item>
                <Nav.Link eventKey="Sub Product">Sub Product</Nav.Link>
              </Nav.Item>
            </Col>
            <Col className="text-center">
              <Nav.Item>
                <Nav.Link eventKey="TbkProduct">Teeblanka Product</Nav.Link>
              </Nav.Item>
            </Col>
            <Col className="text-center">
              <Nav.Item>
                <Nav.Link eventKey="TelProduct">Teelelo Product</Nav.Link>
              </Nav.Item>
            </Col>
          </Nav>
        </Row>
      </Card>
      <div>{renderContent()}</div>
    </div>
  );
}

export default MainTabs;
