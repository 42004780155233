import React from "react";

function AdminFooter() {
  return (
    <div>
      <h2>Admin Footer</h2>
    </div>
  );
}

export default AdminFooter;
