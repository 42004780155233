import React from "react";
import noImg from "../../Assets/noimage.png";
import "./product.css";
import { FaTrash } from "react-icons/fa";
import { Button, Spinner } from "react-bootstrap";
import { getImageUrl } from "../../MIS/Global";

const MainProductList = ({ data, onEdit, onDelete,isLoading,isError}) => {
  return (
    <>
      <div className="table-responsive auto-fill">
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "250px" }}
          >
            <Spinner animation="border" />
          </div>
        ) : isError ? (
          <div className="text-danger text-center">Failed to load data.</div>
        ) : data?.length > 0 ? (
          <table className="table table-sm w-100">
            <tbody className="list">
              {data?.map((element, index) => {
                return (
                  <tr key={index} className="product-card">
                    <td className="d-flex align-items-center gap-2" 
                     onClick={(e)=>{onEdit(element)}}
                    >
                    {/* {getImageUrl(`/${element?.imgpath}`)} */}
                      <img
                        src={getImageUrl(`${element?.imgpath}`) || noImg}
                        className="rounded"
                        alt="Main Product"
                        style={{ width: "55px", height: "55px",objectFit:"contain" }}
                      />
                      <div>
                        <h6>{element?.mpname}</h6>
                        <h6 className="text-muted" style={{fontSize:"14px"}}>{element?.catgname}</h6>
                      </div>
                    </td>
                    <Button
                      variant="danger"
                      size="sm"
                      className="delete-icon position-absolute top-0 end-0 m-2 d-flex justify-content-center p-1"
                      //   onClick={onDelete}
                      onClick={(e)=>{onDelete(element?.id)}}
                    >
                      <FaTrash />
                    </Button>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="text-center">No sizes available.</div>
        )}
      </div>
    </>
  );
};

export default MainProductList;
