import { Formik,Form } from 'formik';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ButtonEL from '../../GlobalComponent/Elements/ButtonEL';
import { FaRegEdit, FaSave, FaTrash } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import FloatingLabelInput from '../../GlobalComponent/FloatingLabelInput';
import SearchField from '../../GlobalComponent/SearchField ';
import { productCatg_addItem, productCatg_deleteItem, productCatg_fetchItems } from '../../../API/Service/productCategoryService';
import useProductCategoryConfig from '../../../API/Hooks/useProductCategoryConfig';
import { setProductCatgData, setProductCatgLoading } from '../../../API/Reducer/productCategoryReducer';
import { showToast } from '../../../API/Reducer/toastSlice';
import { useModal } from '../../ConfirmationModal/ModalContext';

const CategoryForm = () => {
    const queryClient = useQueryClient();
    const { validationSchema, initialValues } = useProductCategoryConfig();
    const { productCatgData,productCatgLoading } = useSelector((state) => state.product_category);
    const dispatch=useDispatch();
    const [searchTerm, setSearchTerm] = useState("");
    const { showModal } = useModal();

   const { data: categories=[], isLoading, isError } = useQuery({
    queryKey: ["categories"],
    queryFn: productCatg_fetchItems,
    onSuccess: (data) => {            
      // console.log("Fetched sizes data:", data);
    },
  });
  const saveMutation = useMutation({
    mutationFn: productCatg_addItem,
    onMutate: () => {      
      dispatch(setProductCatgLoading())
    },
    onSuccess: (resData) => {      
      queryClient.invalidateQueries(["categories"]);
      if(resData?.code==200 && Object.keys(resData?.data).length>0){
        dispatch(
            showToast({
              message: "Record saved successfully",
              variant: "success",
            })
          );          
      }else if(resData?.code==200 && resData?.data.length<=0){
        dispatch(
            showToast({
              message: `This Product Category Already Exist`,
              variant: "danger",
            })
          );
      }else{
        dispatch(
            showToast({
              message: "something went wrong; Please try again!",
              variant: "danger",
            })
          );
      }        
    },
    onError: (error) => {
      dispatch(
        showToast({
          message: "Some thing went wrong; \nPlease contact your administrator!",
          variant: "danger",
        })
      );
    },
    onSettled: () => {
      dispatch(setProductCatgLoading())
      dispatch(setProductCatgData({}));
    },
  });
  const deleteMutation = useMutation({
    mutationFn: productCatg_deleteItem,
    onSuccess: () => {
      queryClient.invalidateQueries(["categories"]); // Refetch sizes after deletion
      dispatch(
        showToast({
          message: "Category deleted successfully!",
          variant: "success",
        })
      );
    },
    onError: (error) => {      
      dispatch(
        showToast({
          message: "Failed to delete catgeory; please try again.",
          variant: "danger",
        })
      );
    },    
  });

  const handleSubmit = (values, { resetForm }) => {    
    const formData = new FormData();
    if(values?.id<=0){delete values['id']}
    Object.keys(values).forEach((key) => {
        const value = values[key];
        if (value) {
          formData.append(key, value);
        }
    });
    showModal({
      title: "Save Confirmation",
      message: "Are you sure you want to save this record?",
      onConfirm: () => {
        saveMutation.mutate(formData, {
          onSuccess: () => {
            resetForm();
          },
        });
      },
      confirmText: "Yes",
      cancelText: "No",
    });
    
  };

  const handleDelete = (id) => {
    showModal({
      title: "Delete Confirmation",
      message: "Are you sure you want to delete this category?",
      onConfirm: () => {
        deleteMutation.mutate({id:id});
      },
      confirmText: "Yes",
      cancelText: "No",
    });
    
  };
  const handleEdit = (data) => {    
    dispatch(setProductCatgData(data));    
  };
  const filteredCategories = categories.filter((element) =>
    element.catgname.toLowerCase().includes(searchTerm.toLowerCase())
  ); 
 
  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  return (
    <>
      <Container fluid>
      <Row>
            <Formik
              initialValues={Object.keys(productCatgData).length > 0 ? productCatgData 
                :initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                validateForm,
                setFieldTouched,
                errors,
                touched,
                getFieldProps,
              }) => (
                <Form autoComplete="off">
                  <Col sm={12}>
                    <FloatingLabelInput
                      id="catgname"
                      label="Category Name"
                      placeholder=""
                      size="md"
                      {...getFieldProps("catgname")}
                      error={errors.catgname}
                      touched={touched.catgname}
                    />
                  </Col>
                  <Col sm={12} className="d-flex justify-content-end">
                  <ButtonEL
                    type="submit"
                    variant="success"
                    isLoading={productCatgLoading}                    
                    loadingText="Saving..."                    
                    icon={<FaSave/>}                    
                  > Save </ButtonEL>
        
                  </Col>
                </Form>
              )}
            </Formik>
          </Row>
          <Row>
            <Col sx={12} className="d-flex justify-content-end pt-2 pb-2">
              <SearchField
                placeholder="Search here..."
                borderRadius="50px"
                className="mserch-input"
                customStyle={{ fontSize: "13.5px" }}
                onSearch={handleSearch}
              />
            </Col>
          </Row>
          <Row>
            <Col sx={12}>
             <div className="scrollable-table" style={{ maxHeight: "250px" }}>
              {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: "250px" }}>
                    <Spinner animation="border" />
                  </div>
                ) : isError ? (
                  <div className="text-danger text-center">Failed to load data.</div>
                ) : categories?.length > 0 ? (
                <table className="table table-sm w-100 table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {filteredCategories?.map((element, index) => {
                      return (
                        <tr className="p-2" key={index}>
                          <td>{element?.catgname}</td>
                          <td className="d-flex justify-content-end align-items-center gap-2">
                            <FaRegEdit
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleEdit(element)}
                            />
                            <FaTrash
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDelete(element?.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                ): (
                  <div className="text-center">No categories available.</div>
                )}
              </div>
            </Col>
          </Row>
      </Container>  
    </>
  )
}

export default CategoryForm