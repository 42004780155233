import React from 'react'
import { Container,Modal } from 'react-bootstrap'
import { setProductCatgData, setProductCatgModal } from '../../../API/Reducer/productCategoryReducer';
import { useDispatch, useSelector } from 'react-redux';
import CategoryForm from './CategoryForm';

const CategoryPage = () => {
    const dispatch=useDispatch();
    const { productCatgModal } = useSelector((state) => state.product_category);
  return (
    <>
     <Modal show={productCatgModal} onHide={() =>{dispatch(setProductCatgModal());
     dispatch(setProductCatgData({}));}}>
        <Modal.Header closeButton>
          <h4>Product Category</h4>
        </Modal.Header>
        <Modal.Body>
        <Container>
            <CategoryForm/>
        </Container>
        </Modal.Body>
        </Modal>
    </>
  )
}

export default CategoryPage