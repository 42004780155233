import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "react-bootstrap";
import useSubProductConfig from "../../../API/Hooks/useSubProductConfig";
import SubProductForm from "./SubProductForm";
import { useDispatch, useSelector } from "react-redux";
import ButtonEL from "../../GlobalComponent/Elements/ButtonEL";
import { FaSave } from "react-icons/fa";
import { FaArrowsRotate } from "react-icons/fa6";

import FabricList from "./FabricList";
import SizeList from "./SizeList";
import {
  setMpId,
  setSubDescription,
  setSubProductData,
  setSubProductLoading,
} from "../../../API/Reducer/mainProductReducer";
import { showToast } from "../../../API/Reducer/toastSlice";
import {
  subProduct_addItem,
  subProduct_deleteItem,
} from "../../../API/Service/subProductService";
import {
  setFabricSelectedList,
  setSizeSelectedList,
} from "../../../API/Reducer/fabricReducer";
import SubProductList from "./SubProductList";
import SearchField from "../../GlobalComponent/SearchField ";
import { useModal } from "../../ConfirmationModal/ModalContext";

const SubProduct = ({ mainproducts, data }) => {
  const queryClient = useQueryClient();
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { validationSchema, initialValues } = useSubProductConfig();
  const { mpid, subProductLoading, subDescription, subProductData } =
    useSelector((state) => state.mainproduct);
  const { sizeSelectedList, fabricSelectedList } = useSelector(
    (state) => state.fabric
  );
  const dispatch = useDispatch();
  const selectRef = useRef(null);
  const { showModal } = useModal();

  const clearAll = () => {
    selectRef.current.reset();
    dispatch(setMpId(0));
    const updatedFabrics = fabricSelectedList.map((item) => ({
      ...item,
      is_check: false,
    }));
    dispatch(setFabricSelectedList(updatedFabrics));

    const updatedItems = sizeSelectedList.map((item) => ({
      ...item,
      is_check: false,
      ps_price: 0,
      ps_mrp: 0,
      ps_whlprice: 0,
    }));
    dispatch(setSizeSelectedList(updatedItems));

    dispatch(setSubProductData({}));
    dispatch(setSubDescription({}));
  };
  const saveMutation = useMutation({
    mutationFn: subProduct_addItem,
    onMutate: () => {
      dispatch(setSubProductLoading());
    },
    onSuccess: (resData) => {
      queryClient.invalidateQueries(["subproducts"]);
      if (resData?.code == 200 && Object.keys(resData?.data).length > 0) {
        dispatch(
          showToast({
            message: "Record saved successfully",
            variant: "success",
          })
        );
        clearAll();
      } else if (resData?.code == 200 && resData?.data.length <= 0) {
        dispatch(
          showToast({
            message: `This Sub-Product Already Exist`,
            variant: "danger",
          })
        );
      } else if (resData?.code == 200 && resData?.data == -1) {
        dispatch(
          showToast({
            message: resData?.msg,
            variant: "danger",
          })
        );
      } else {
        dispatch(
          showToast({
            message: "something went wrong; Please try again!",
            variant: "danger",
          })
        );
      }
    },
    onError: (error) => {
      dispatch(
        showToast({
          message:
            "Some thing went wrong; \nPlease contact your administrator!",
          variant: "danger",
        })
      );
      // dispatch(setSubProductLoading())
    },
    onSettled: () => {
      dispatch(setSubProductLoading());
    },
  });

  const deleteMutation = useMutation({
    mutationFn: subProduct_deleteItem,
    onSuccess: () => {
      queryClient.invalidateQueries(["subproducts"]); // Refetch sizes after deletion
      dispatch(
        showToast({
          message: "Sub-Product deleted successfully!",
          variant: "success",
        })
      );
    },
    onError: (error) => {
      dispatch(
        showToast({
          message: "Failed to delete sub-product; please try again.",
          variant: "danger",
        })
      );
    },
  });

  const handleSubmit = (values, { resetForm }) => {
    // Validate the select input
    const isValid = selectRef.current.validate();
    if (!isValid) {
      return selectRef.current.validate(); // Trigger validation for feedback
    }
    const filteredSizes = sizeSelectedList.filter((item) => item.is_check);
    const filteredFabrics = fabricSelectedList.filter((item) => item.is_check);

    if (filteredFabrics.length <= 0) {
      dispatch(
        showToast({
          message: "Please select at least one fabric ",
          variant: "danger",
        })
      );
      return;
    } else if (filteredSizes.length <= 0) {
      dispatch(
        showToast({
          message: "Please select at least one size ",
          variant: "danger",
        })
      );
      return;
    }
    const formData = new FormData();
    try {
      values = { ...values };
      delete values["imgpath"];
    } catch (error) {}
    if (values?.id > 0) {
      formData.append("id", values?.id);
    }
    if (mpid > 0) {
      formData.append("mpid", mpid);
    } else {
      formData.append("mpid", values?.mpid);
    }
    formData.append("fbid", JSON.stringify(filteredFabrics));
    formData.append("sid", JSON.stringify(filteredSizes));
    formData.append("subp_descp", JSON.stringify(subDescription));
    formData.append("subpname", values?.subpname);
    formData.append("loginid", values?.loginid);
    if (file) {
      formData.append("imgpath", file);
    }
    showModal({
      title: "Save Confirmation",
      message: "Are you sure you want to save this record?",
      onConfirm: () => {
        saveMutation.mutate(formData, {
          onSuccess: () => {
            resetForm();
            setFile(null);
          },
        });
      },
      confirmText: "Yes",
      cancelText: "No",
    });
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input's click event
    }
  };
  const filteredSubProducts = data?.subProducts?.filter((element) =>
    element.subpname.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  const handleDelete = (id) => {
    showModal({
      title: "Delete Confirmation",
      message: "Are you sure you want to delete this sub-product?",
      onConfirm: () => {
        deleteMutation.mutate({ id: id });
      },
      confirmText: "Yes",
      cancelText: "No",
    });
  };

  const updateSizeIs_Check = (data) => {
    const priceLookup = data?.sizeProductSet.reduce((acc, item) => {
      acc[item.sid] = item;
      return acc;
    }, {});

    const updatedArray = sizeSelectedList.map((item) => {
      if (priceLookup[item.id]) {
        return {
          ...item,
          ps_price: priceLookup[item.id].ps_price,
          ps_mrp: priceLookup[item.id].ps_mrp,
          ps_whlprice: priceLookup[item.id].ps_whlprice,
          sid: priceLookup[item.id].id,
          is_check: true,
        };
      }
      return {...item,is_check: false};
    });
    dispatch(setSizeSelectedList(updatedArray));
  };

  const updateFabricIs_Check = (data) => {
    const priceLookup = data?.fabricProductSet.reduce((acc, item) => {
      acc[item.fbid] = item;
      return acc;
    }, {});

    const updatedArray = fabricSelectedList.map((item) => {
      if (priceLookup[item.id]) {
        return {
          ...item,
          is_check: true,
          fbid: priceLookup[item.id].id,
        };
      }
      return {...item,is_check: false};
    });

    dispatch(setFabricSelectedList(updatedArray));
  };

  const handleEdit = (data) => {    
    updateFabricIs_Check(data);
    updateSizeIs_Check(data);
    const jsonData = JSON.parse(
      data?.subp_descp
        .replace(/'/g, '"') // Replace single quotes with double quotes
        .replace(/False/g, "false") // Replace Python `False` with JS `false`
        .replace(/True/g, "true") // Replace Python `True` with JS `true` (if needed)
    );
    dispatch(setSubDescription(jsonData));
    dispatch(setSubProductData(data));
  };

  const handleReset=()=>{
    data?.resetForm();
    clearAll();
  }
  
  return (
    <>
      <Formik
        initialValues={
          Object.keys(subProductData).length > 0
            ? subProductData
            : initialValues
        }
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, getFieldProps }) => (
          <Form autoComplete="off">
            <Container fluid className="pt-1">
              <Row>
                <Col sm={8}>
                  <Card className="shadow">
                    <CardBody>
                      <Row>
                        <Col sm={12}>
                          <SubProductForm
                            file={file}
                            fileInputRef={fileInputRef}
                            handleFileChange={handleFileChange}
                            handleButtonClick={handleButtonClick}
                            errors={errors}
                            touched={touched}
                            getFieldProps={getFieldProps}
                            selectRef={selectRef}
                            mainproducts={mainproducts}
                          />
                        </Col>
                      </Row>
                      <Row className="p-2">
                        <Col sm={4}>
                          <FabricList fabrics={data} />
                        </Col>
                        <Col sm={8}>
                          <SizeList data={data} />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          sm={12}
                          className="d-flex justify-content-end align-items-center gap-2"
                        >
                         <ButtonEL
                            type="button"
                            variant="success"
                            isLoading={false}
                            loadingText="Saving..."
                            icon={<FaArrowsRotate className="icon"/>}
                            onClick={handleReset}
                            className="reset-button"
                          >
                            Reset
                          </ButtonEL>
                          <ButtonEL
                            type="submit"
                            variant="success"
                            isLoading={subProductLoading}
                            loadingText="Saving..."
                            icon={<FaSave />}
                          >
                            Save
                          </ButtonEL>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={4}>
                  <Card className="shadow">
                    <CardBody>
                      <Row>
                        <Col className="d-flex justify-content-end align-items-center mb-1">
                          <SearchField
                            placeholder="Search here..."
                            borderRadius="50px"
                            className="mserch-input"
                            customStyle={{ fontSize: "13.5px" }}
                            onSearch={handleSearch}
                          />
                        </Col>
                        <hr />
                        <Col sm={12} className="w-100">
                          <SubProductList
                            data={filteredSubProducts}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                            isLoading={data?.isLoadingSubProduct}
                            isError={data?.isErrorSubProduct}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SubProduct;
