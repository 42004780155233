
import axios from "axios";
import { getUrl } from "../../MIS/Global";
import { showToast } from "../Reducer/toastSlice";



// Axios instance for making API calls (you can configure it as needed)
const apiClient = axios.create({
  baseURL: getUrl(),  // Replace with your API base URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Fetch all items (GET request)
export const size_fetchItems = async () => {
  let data={page:1,limit:1000}
  const queryParams = new URLSearchParams(data).toString();
  const response = await apiClient.get(`size/?${queryParams}`);  
  return response?.data?.data;
};

// Add an item (POST request)
export const size_addItem = async (newItem) => {
  if(newItem?.id > 0){    
    const response = await apiClient.patch("size/", newItem);  
    return response.data;
  }else{
    delete newItem.id  
    const response = await apiClient.post("size/", newItem);  
    return response.data;
  }
  
};

// Update an item (PUT request)
export const updateItem = async (id, updatedItem) => {    
  const response = await apiClient.put(`/items/${id}`, updatedItem);
  return response.data;
};

// Delete an item (DELETE request)
export const size_deleteItem = async (newItem) => {    
  const queryParams = new URLSearchParams(newItem).toString();
  const response = await apiClient.delete(`size/?${queryParams}`);
  return response.data;
};
