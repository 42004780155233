import { useDispatch } from 'react-redux';
import { showToast } from '../Reducer/toastSlice';


const useToast = () => {
  const dispatch = useDispatch();

  /**
   * Show a toast notification with a custom message and variant.
   *
   * @param {string} message - The message to display in the toast.
   * @param {string} variant - The variant of the toast (e.g., 'success', 'error').
   */
  const triggerToast = (message, variant = 'info') => {
    dispatch(
      showToast({
        message,
        variant,
      })
    );
  };

  return triggerToast;
};

export default useToast;
