import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";

const SelectEL = forwardRef(
  (
    {
      onChange,
      options = [],
      defaultText = "Select an option",
      size = "sm",
      keyName = "value",
      keyId = "id",
      required = false,
      errorText = "This field is required",
      selectedValue = 0,
    },
    ref
  ) => {
    const [value, setValue] = useState(""); // State to track selected value
    const [error, setError] = useState(false); // State to track validation errors

    // Effect to set initial value from selectedValue
    useEffect(() => {
      if (selectedValue > 0) {
        const matchedOption = options.find((option) => option[keyId] === selectedValue);
        if (matchedOption) {
          setValue(matchedOption[keyId]);
        }else{
          ref.current.reset();
        }
      }
    }, [selectedValue, options, keyId]);

    // Function to handle value change
    const handleChange = (e) => {
      const selectedValue = e.target.value;
      setValue(selectedValue);
      setError(false); // Clear error when valid value is selected
      if (onChange) onChange(selectedValue);
    };

    // Function to validate the select input
    const validate = () => {
      if (required && !value) {
        setError(true);
        return false;
      }
      return true;
    };

    // Function to handle blur
    const handleBlur = () => {
      if (required && !value) {
        setError(true);
      }
    };

    const reset = () => {
      setValue("");
      setError(false);
    };
    // Expose validate method to parent component via ref
    useImperativeHandle(ref, () => ({
      validate,
      reset
    }));

    return (
      <div>
        <select
          className={`form-select form-select-${size} ${error ? "is-invalid" : ""}`}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur} // Trigger validation on blur
          aria-label="Select Menu"
        >
          <option value="">{defaultText}</option>
          {options.map((option, index) => (
            <option key={index} value={option[keyId]}>
              {option.label || option[keyName]}
            </option>
          ))}
        </select>
        {error && <div className="invalid-feedback">{errorText}</div>}
      </div>
    );
  }
);

export default SelectEL;
