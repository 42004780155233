import * as Yup from "yup";

const useProductCategoryConfig = () => {
  const validationSchema = Yup.object({
    catgname: Yup.string().required("Category name is required"),    
  });

  const initialValues = {
    id:0,
    catgname: "",
    imgpath: "-",    
    loginid:0,
  };

  return { validationSchema, initialValues };
};

export default useProductCategoryConfig;
